//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-56:_756,_6064,_6780,_9704,_2764,_1240,_2820,_1252;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-56')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-56', "_756,_6064,_6780,_9704,_2764,_1240,_2820,_1252");
        }
      }catch (ex) {
        console.error(ex);
      }